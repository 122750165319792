<template>
    <div class="container-fluid">
        
        <div class="block">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>標題(中文)</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="請輸入標題(中文)"
                            maxlength="50"
                            v-model.trim="smokingInfo.TitleChinese"
                        />
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>標題(英文)</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="請輸入標題(英文)"
                            maxlength="100"
                            v-model.trim="smokingInfo.TitleEnglish"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="block">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>問卷說明(中文)</label>
                        <textarea
                            cols="30"
                            rows="3"
                            class="form-control"
                            placeholder="請輸入問卷說明(中文)"
                            maxlength="200"
                            v-model.trim="smokingInfo.DescriptionChinese"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>問卷說明(英文)</label>
                        <textarea
                            cols="30"
                            rows="3"
                            class="form-control"
                            placeholder="請輸入問卷說明(英文)"
                            maxlength="400"
                            v-model.trim="smokingInfo.DescriptionEnglish"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="block">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>填寫完整頁面說明(中文)</label>
                        <textarea
                            cols="30"
                            rows="3"
                            class="form-control"
                            placeholder="填寫完整頁面說明(中文)"
                            maxlength="200"
                            v-model.trim="smokingInfo.CompletedChinese"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>填寫完整頁面說明(英文)</label>
                        <textarea
                            cols="30"
                            rows="3"
                            class="form-control"
                            placeholder="填寫完整頁面說明(英文)"
                            maxlength="400"
                            v-model.trim="smokingInfo.CompletedEnglish"
                        />
                    </div>
                </div>
            </div>
        </div>
        

        <div class="card search-card">
            <div class="card-body">
                <div class="form-row">
                    <div class="col-sm-3 col-md-2">
                        <div class="form-group">
                        <label>座位區域</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="請輸入關鍵字"
                            @keydown.stop.prevent.enter="getSmokeAreaPages(1, areaTableInfo.per_page)"
                            v-model.trim="smokingKeyword"
                        />
                        </div>
                    </div>

                    <div class="col-sm-3 col-md-3">
                        <button
                            type="button"
                            class="btn btn-primary btn-search"
                            style="margin-top: 30px; margin-left: 10px"
                            @click.prevent="getSmokeAreaPages(1, areaTableInfo.per_page)"
                        >
                        {{ i18n("Buttons.Search") }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-create"
                            style="margin-top: 30px; margin-left: 10px"
                            data-toggle="modal"
                            data-target="#areaModal"
                            @click="commonModal(true)"
                        >
                        新增座位區域
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="yahome-table-wrap">
            <VueBootstrap4Table :rows="areaRows" :columns="areaColumns" :config="config" :classes="classes" @on-change-query="onChangeBuliding" :total-rows="areaTotal">

                <!-- <template slot="edit" slot-scope="props">
                <button
                    class="btn btn-info btn-sm"
                    style="margin-right: 0.5rem"
                    data-toggle="modal"
                    data-target="#areaModal"
                    @click="commonModal(false, props.row)"
                >
                    {{ i18n("Buttons.Edit") }}
                </button>
                </template> -->

                <template slot="delete" slot-scope="props">
                <button
                    class="btn btn-danger btn-delete btn-sm"
                    data-toggle="modal"
                    data-target="#delAreaModal"
                    @click="commonModal(false, props.row)"
                >
                    {{ i18n("Buttons.Delete") }}
                </button>
                </template>
                <template slot="empty-results"> 暫無資料 </template>
            </VueBootstrap4Table>
        </div>

        <div class="card search-card">
            <div class="card-body">
                <div class="form-row">
                    <div class="col-sm-3 col-md-2">
                        <div class="form-group">
                        <label>座位號碼</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="請輸入關鍵字"
                            @keydown.stop.prevent.enter="getSeatNoPages(1, seatTableInfo.per_page)"
                            v-model.trim="seatKeyword"
                        />
                        </div>
                    </div>

                    <div class="col-sm-3 col-md-3">
                        <button
                            type="button"
                            class="btn btn-primary btn-search"
                            style="margin-top: 30px; margin-left: 10px"
                            @click.prevent="getSeatNoPages(1, seatTableInfo.per_page)"
                        >
                        {{ i18n("Buttons.Search") }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-create"
                            style="margin-top: 30px; margin-left: 10px"
                            data-toggle="modal"
                            data-target="#seatModal"
                            @click="commonModal(true)"
                        >
                        新增座位號碼
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="yahome-table-wrap">
            <VueBootstrap4Table :rows="seatRows" :columns="seatColumns" :config="config" :classes="classes" @on-change-query="onChangeArea" :total-rows="seatTotal">

                <!-- <template slot="edit" slot-scope="props">
                <button
                    class="btn btn-info btn-sm"
                    style="margin-right: 0.5rem"
                    data-toggle="modal"
                    data-target="#seatModal"
                    @click="commonModal(false, props.row)"
                >
                    {{ i18n("Buttons.Edit") }}
                </button>
                </template> -->

                <template slot="qrcode" slot-scope="props">
                <button
                    class="btn btn-info btn-sm"
                    style="margin-right: 0.5rem"
                    data-toggle="modal"
                    data-target="#qrcodeModal"
                    @click="showQrcode(props.row)"
                >
                    產生QRCode
                </button>
                </template>

                <template slot="delete" slot-scope="props">
                <button
                    class="btn btn-danger btn-delete btn-sm"
                    data-toggle="modal"
                    data-target="#delSeatModal"
                    @click="commonModal(false, props.row)"
                >
                    {{ i18n("Buttons.Delete") }}
                </button>
                </template>
                <template slot="empty-results"> 暫無資料 </template>
            </VueBootstrap4Table>
        </div>

        <!-- <div class="block">
            <div class="row">
                <div class="col-md-8">
                    <div class="form-group">
                        <label>填寫完整頁面說明(中文)</label>
                        <textarea
                            cols="30"
                            rows="3"
                            class="form-control"
                            placeholder="填寫完整頁面說明(中文)"
                            v-model.trim="restaurantInfo.CompletedChinese"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-8">
                    <div class="form-group">
                        <label>填寫完整頁面說明(英文)</label>
                        <textarea
                            cols="30"
                            rows="3"
                            class="form-control"
                            placeholder="填寫完整頁面說明(英文)"
                            v-model.trim="restaurantInfo.CompletedEnglish"
                        />
                    </div>
                </div>
            </div>
        </div> -->

        <!-- fiexed button -->
        <button class="btn btn-success" id="save" @click.prevent="saveSmokingZoneCnrDesign">
            <!-- <i class="fa fa-angle-up"></i> -->
            儲存
        </button>

        <!-- 座位區域 Modal -->
        <div
            id="areaModal"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            data-backdrop="static"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <ValidationObserver
                    ref="building"
                    class="modal-content"
                    v-slot="{ handleSubmit, reset }"
                >
                <form @reset.prevent="reset">
                    <div class="modal-header">
                        <span v-if="isAdd">新增座位區域</span>
                        <span v-else>編輯座位區域</span>
                    </div>
                    <div class="modal-body">
                        <div class="field">
                            <ValidationProvider
                                tag="div"
                                :rules="`my_not:${nowItem.Name}|required`"
                                v-slot="{ errors }"
                            >
                                <label>
                                    <span class="mark">*</span>座位區域(中文)
                                    <!-- <span class="mark">(不可重複)</span> -->
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    maxlength="50"
                                    v-model="item.SmokeAreaChinese"
                                />
                                <span v-if="errors[0]" class="text-danger">
                                {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </div>

                        <div class="field">
                            <ValidationProvider
                                tag="div"
                                :rules="`my_not:${nowItem.Name}|required`"
                                v-slot="{ errors }"
                            >
                                <label>
                                    <span class="mark">*</span>座位區域(英文)
                                    <!-- <span class="mark">(不可重複)</span> -->
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    maxlength="50"
                                    v-model="item.SmokeAreaEnglish"
                                />
                                <span v-if="errors[0]" class="text-danger">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="reset"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            {{ i18n("Buttons.Cancel") }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-success"
                            @click="handleSubmit(saveSmokeArea)"
                        >
                            {{ i18n("Buttons.Save") }}
                        </button>
                    </div>
                </form>
                </ValidationObserver>
            </div>
        </div>

        <!-- 刪除座位區域 Modal -->
        <div
            id="delAreaModal"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            data-backdrop="static"
        >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">刪除座位區域</div>
                <div class="modal-body">
                    <!-- 確認刪除 -->
                    刪除此筆資料會連動座位號碼，且刪除後不能再新增同名
                    <span style="font-weight: bolder; color: red">[區域]</span>
                    ，請確認是否刪除
                    <span style="font-weight: bolder; color: red">
                    {{ item.SmokeAreaChinese }} </span
                    >這筆資料?
                </div>
                <div class="modal-footer">
                    <button type="reset" class="btn btn-secondary" data-dismiss="modal">
                    {{ i18n("Buttons.Cancel") }}
                    </button>
                    <button type="button" class="btn btn-danger" @click="delSmokeArea">
                    {{ i18n("Buttons.Delete") }}
                    </button>
                </div>
            </div>
        </div>
        </div>

        <!-- 座位號碼 Modal -->
        <div
            id="seatModal"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            data-backdrop="static"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <ValidationObserver
                    ref="area"
                    class="modal-content"
                    v-slot="{ handleSubmit, reset }"
                >
                    <form @reset.prevent="reset">
                        <div class="modal-header">
                            <span v-if="isAdd">新增座位號碼</span>
                            <span v-else>編輯座位號碼</span>
                        </div>
                        <div class="modal-body">
                            <div class="field">
                                <ValidationProvider
                                    tag="div"
                                    rules="required"
                                    v-slot="{ errors }"
                                >
                                    <label>
                                        <span class="mark">*</span>座位區域
                                        <!-- <span class="mark">(不可重複)</span> -->
                                    </label>
                                    <v-select
                                        :options="areaOptions"
                                        label="SmokeAreaChinese"
                                        placeholder="請選擇"
                                        :reduce="(smokeAreaOption) => smokeAreaOption.Guid"
                                        v-model="item.SmokeAreaGuid"
                                    >
                                        <!-- <template slot-scope="position">
                                            {{ position.BuildingChinese }}
                                        </template> -->
                                    </v-select>
                                    <span v-if="errors[0]" class="text-danger">
                                    {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>

                            <div class="field">
                                <ValidationProvider
                                    tag="div"
                                    :rules="`my_not:${nowItem.Name}|required`"
                                    v-slot="{ errors }"
                                >
                                    <label>
                                        <span class="mark">*</span>座位號碼
                                        <!-- <span class="mark">(不可重複)</span> -->
                                    </label>
                                    <input
                                        type="number"
                                        class="form-control"
                                        maxlength="50"
                                        v-model="item.SeatNo"
                                        oninput="value=this.value.replace(/\D+/g,'')"
                                    />
                                    <span v-if="errors[0]" class="text-danger">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button
                                type="reset"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                {{ i18n("Buttons.Cancel") }}
                            </button>
                            <button
                                type="button"
                                class="btn btn-success"
                                @click="handleSubmit(saveSeatNo)"
                            >
                                {{ i18n("Buttons.Save") }}
                            </button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>

        <!-- 刪除座位號碼 Modal -->
        <div
            id="delSeatModal"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            data-backdrop="static"
        >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">刪除座位號碼</div>
                <div class="modal-body">
                    刪除後在此區域不能再新增同名
                    <span style="font-weight: bolder; color: red">[座位號碼]</span>，
                    是否確認刪除座位號碼
                    <span style="font-weight: bolder; color: red">
                    {{ item.SeatNo }} </span
                    >?
                </div>
                <div class="modal-footer">
                    <button type="reset" class="btn btn-secondary" data-dismiss="modal">
                    {{ i18n("Buttons.Cancel") }}
                    </button>
                    <button type="button" class="btn btn-danger" @click="delSeatNo">
                    {{ i18n("Buttons.Delete") }}
                    </button>
                </div>
            </div>
        </div>
        </div>

        <!--顯示Qrcode-->
        <div id="qrcodeModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div
                    style="display:block; text-align:center; color:#FFF; padding:20px;"
                >
                    QRcode
                </div>
                    <div class="modal-body">
                        <template>
                            <qrcode-vue
                                id="qrcode"
                                :value="QRcode"
                                :size="size"
                                class="qrcode-wrap"
                                level="H"
                            />
                        </template>
                        <div>
                            <!-- <span style="color: white;font-size: .8em;">{{ QRcode }}</span> -->
                            <input
                                v-if="QRcode"
                                id="copyText"
                                type="text"
                                size="5"
                                style="opacity: 0;width: 100%;"
                                v-model="QRcode"
                            />
                        </div>
                        <div style="margin-top: 12px">
                            <button
                                class="btn btn-primary mr-2"
                                @click.prevent="downloadQRCode(`${QRCodeItem.SmokeAreaChinese}-${QRCodeItem.SeatNo}`)"
                            >
                                下載
                            </button>
                            <button
                                ref="copyButton"
                                class="btn btn-info mr-2"
                                data-clipboard-target="#copyText"
                                @click="initClipboard"
                            >
                                <span style="color: #fff">
                                {{ i18n("Basic.CopyLink") }}
                                </span>
                            </button>
                            <button
                                type="reset"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                {{ i18n("Buttons.Cancel") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
/* global $ */
import { config } from "@/components/table_config.js";
import { mapGetters, mapActions } from "vuex";
import { apiGetSmokeAreaAll, apiGetSmokeAreaPages, apiAddSmokeArea, apiEditSmokeArea, apiGetSeatNoPages, apiAddSeatNo, apiEditSeatNo, apiGetSmokingZoneCnrDesign, apiSaveSmokingZoneCnrDesign } from '@/apis/smokingManage'

import QrcodeVue from "qrcode.vue";
import Clipboard from "clipboard";
// import CommonModal from '@/components/Modal/commonModal.vue'

export default {

  components: {
    QrcodeVue
  },

  data() {
    return {

      // Modal狀態
      isAdd: false,

      // 判斷角色名稱是否重複
      item: {
          BuildingGuid: null
      },
      nowItem: {},

      smokingInfo: {
        TitleChinese: null,
        TitleEnglish: null,
        DescriptionChinese: null,
        DescriptionEnglish: null,
        CompletedChinese: null,
        CompletedEnglish:null
      },
      
      smokingKeyword: null,
      seatKeyword: null,

      areaRows: [],
      areaColumns: [
        {
          label: "座位區域(中文)",
          name: "SmokeAreaChinese",
        },
        {
          label: "座位區域(英文)",
          name: "SmokeAreaEnglish",
        },
        {
          label: "",
          slot_name: "edit",
        },
        {
          label: "",
          slot_name: "delete",
        },
      ],
      areaTotal: 0,

      seatRows: [],
      seatColumns: [
        {
          label: "座位區域",
          name: "SmokeAreaChinese",
        },
        {
          label: "座位號碼",
          name: "SeatNo",
        },
        // {
        //   label: "區域(英文)",
        //   name: "AreaEnglish",
        // },
        {
          label: "",
          slot_name: "edit",
        },
        {
          label: "",
          slot_name: "qrcode",
        },
        {
          label: "",
          slot_name: "delete",
        },
      ],
      seatTotal: 0,

      config: {
          ...config,
          server_mode: true,
      },
      classes: {
          tableWrapper: "table-responsive footprint-table",
          cell: {
              "text-left" : true
          },
      },
      areaTableInfo: {
          // 分頁資訊
          page: 1,
          per_page: 10,
      },
      seatTableInfo: {
          // 分頁資訊
          page: 1,
          per_page: 10,
      },

      areaOptions: [],

      QRcode: '',
      QRCodeItem: {},
      size: 300,

      clipboard: null,
    };
  },

  computed: {
    ...mapGetters(["lang"]),
  },

  watch: {
    lang: function() {

    },

  },

  methods: {
    ...mapActions(['updateLoading']),
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    //  新增&編輯&刪除 Modal
    commonModal(isAdd, item) {
      if (!isAdd) {
        this.item = { ...item };
        // 原data
        this.nowItem = {
          ...item,
        };
        this.isAdd = false;
      } else {
        // this.item = {BuildingGuid: null};
         this.item = {};
        this.isAdd = true;
      }
      this.$refs.building.reset();
      this.$refs.area.reset();
    },

    // 顯示 QRcode
    showQrcode(item) {
        const url = window.location.origin;
        
        this.QRcode = `${url}/#/smoking/${item.Guid}`;
        this.QRCodeItem = item
    },

    //  複製套件 初始化 (避免 success.length > 0)
    initClipboard() {
        //成功
        this.clipboard.on("success", () => {
            this.$notify({
                title: "成功",
                message: "成功複製連結",
                type: "success",
                duration: 1500,
            });

        });
        setTimeout(() => {
            this.clipboard.destroy();
            this.clipboard = new Clipboard(this.$refs.copyButton);
        }, 100)
    },

    onChangeBuliding(queryParams) {
        const vm = this;
        console.log('queryParams', queryParams)
        // console.log(JSON.parse(JSON.stringify(queryParams)));

        vm.areaTableInfo = {
            page: queryParams.page,
            per_page: queryParams.per_page,
        };
        vm.getSmokeAreaPages(queryParams.page,  queryParams.per_page)

    },
    onChangeArea(queryParams) {
        const vm = this;
        console.log('queryParams', queryParams)
        // console.log(JSON.parse(JSON.stringify(queryParams)));

        vm.seatTableInfo = {
            page: queryParams.page,
            per_page: queryParams.per_page,
        };
        vm.getSeatNoPages(queryParams.page,  queryParams.per_page)

    },

    //  取得全部座位區域
    getSmokeAreaAll() {
        apiGetSmokeAreaAll()
        .then((response) => {
            // console.log(response)

            if(response.status === 200) {
                this.areaOptions = response.data
            }
        })
    },

    //  取得座位區域
    getSmokeAreaPages(page = 1, per_page = 10) {
        apiGetSmokeAreaPages({
            Keyword: this.smokingKeyword,
            Page: page,
            PerPage: per_page,
        }).then((response) => {
            // console.log(response)

            if(response.status === 200) {
                this.areaRows = response.data.Dtos
                this.areaTotal = response.data.DataQuantity
            }
        })
    },

    //  新增座位區域
    addSmokeArea() {

      this.updateLoading(true)
      apiAddSmokeArea({
        ...this.item
      }).then((response) => {
          console.log(response)

          if(response.status === 200) {
            this.item = {};
            $("#areaModal").modal("hide");

            this.getSmokeAreaPages(
                this.areaTableInfo.page,
                this.areaTableInfo.per_page,
            );

            this.$notify({
                title: "成功",
                message: "成功新增座位區域",
                type: "success",
                duration: 3500,
            });

            this.getSmokeAreaAll();
          }

          this.updateLoading(false)
      })
    },

    //  編輯座位區域
    editSmokeArea() {

      this.updateLoading(true)
      apiEditSmokeArea({
        ...this.item
      }).then((response) => {
            console.log(response)

            if(response.status === 200) {
                this.item = {};
                $("#areaModal").modal("hide");

                this.getSmokeAreaPages(
                    this.areaTableInfo.page,
                    this.areaTableInfo.per_page,
                );

                this.$notify({
                    title: "成功",
                    message: "成功編輯座位區域",
                    type: "success",
                    duration: 3500,
                });

                this.getSmokeAreaAll();
            }

            this.updateLoading(false)
      })
    },

    //  刪除座位區域
    delSmokeArea() {
      const vm = this;
      const api = `${window.BaseUrl.api}/Questionnire/DeleteSmokeArea/${vm.item.Guid}`;

      this.updateLoading(true)
      
      vm.$http.delete(api).then((response) => {
            console.log(response)

            if(response.status === 200) {
                this.item = {};
                $("#delAreaModal").modal("hide");

                this.getSmokeAreaPages(
                    this.areaTableInfo.page,
                    this.areaTableInfo.per_page,
                );

                this.getSeatNoPages(
                    this.seatTableInfo.page,
                    this.seatTableInfo.per_page,
                );

                this.$notify({
                    title: "成功",
                    message: "成功刪除座位區域",
                    type: "success",
                    duration: 3500,
                });

                this.getSmokeAreaAll();
            }

            this.updateLoading(false)
      })
      .catch(() => {
        this.updateLoading(false)
      })
    },

    //  儲存座位區域
    saveSmokeArea() {
        if(this.isAdd) {
            this.addSmokeArea()
        } else {
            this.editSmokeArea()
        }
    },

    //  取得座位號碼
    getSeatNoPages(page = 1, per_page = 10) {
        apiGetSeatNoPages({
            Keyword: this.seatKeyword,
            Page: page,
            PerPage: per_page,
        }).then((response) => {
            // console.log(response)

            if(response.status === 200) {
                this.seatRows = response.data.Dtos
                this.seatTotal = response.data.DataQuantity
            }
        })
    },

    //  新增座位號碼
    addSeatNo() {

      this.updateLoading(true)
      apiAddSeatNo({
        ...this.item
      }).then((response) => {
            console.log(response)

            if(response.status === 200) {
                this.item = {};
                $("#seatModal").modal("hide");

                this.getSeatNoPages(
                    this.seatTableInfo.page,
                    this.seatTableInfo.per_page,
                );

                this.$notify({
                    title: "成功",
                    message: "成功新增座位號碼",
                    type: "success",
                    duration: 3500,
                });
            }

            this.updateLoading(false)
      })
    },

    //  編輯座位號碼
    editSeatNo() {

      this.updateLoading(true)
      apiEditSeatNo({
        ...this.item
      }).then((response) => {
            console.log(response)

            if(response.status === 200) {
                this.item = {};
                $("#seatModal").modal("hide");

                this.getSeatNoPages(
                    this.seatTableInfo.page,
                    this.seatTableInfo.per_page,
                );

                this.$notify({
                    title: "成功",
                    message: "成功編輯座位號碼",
                    type: "success",
                    duration: 3500,
                });
            }

            this.updateLoading(false)
      })
    },

    //  刪除座位號碼
    delSeatNo() {
      const vm = this;
      const api = `${window.BaseUrl.api}/Questionnire/DeleteSeatNo/${vm.item.Guid}`;

      this.updateLoading(true)
      
      vm.$http.delete(api).then((response) => {
            console.log(response)

            if(response.status === 200) {
                this.item = {};
                $("#delSeatModal").modal("hide");

                this.getSeatNoPages(
                    this.seatTableInfo.page,
                    this.seatTableInfo.per_page,
                );

                this.$notify({
                    title: "成功",
                    message: "成功刪除座位號碼",
                    type: "success",
                    duration: 3500,
                });
            }

            this.updateLoading(false)
      })
      .catch(() => {
        this.updateLoading(false)
      })
    },

    //  儲存座位號碼
    saveSeatNo() {
        if(this.isAdd) {
            this.addSeatNo()
        } else {
            this.editSeatNo()
        }
    },

    // 最新吸菸區實名制問卷設計
    getSmokingZoneCnrDesign() {
        
        this.updateLoading(true)
        apiGetSmokingZoneCnrDesign()
        .then((response) => {
            // console.log(response)

            if(response.status === 200) {
                this.smokingInfo = response.data
            }

            this.updateLoading(false)
        })
        .catch(() => {
            this.updateLoading(false)
        })
    },

    //  儲存吸菸區實名制問卷設計
    saveSmokingZoneCnrDesign() {

        this.updateLoading(true)
        apiSaveSmokingZoneCnrDesign({
            ...this.smokingInfo
        })
        .then((response) => {
            console.log(response)

            if(response.status === 200) {
                this.$notify({
                    title: "成功",
                    message: "成功儲存",
                    type: "success",
                    duration: 3500,
                });

                this.getSmokingZoneCnrDesign();
            }

            this.updateLoading(false)
        })
    },
    
    downloadQRCode(filename) {  //  下載QRCode
        let canvas = document.getElementsByTagName('canvas')

        let a = document.createElement('a')
        a.href = canvas[0].toDataURL();
        a.download = filename || 'QRCode.png'

        a.click()

    },

  },

  mounted() {
      this.getSmokingZoneCnrDesign()
      this.getSmokeAreaAll()

      this.getSmokeAreaPages()
      this.getSeatNoPages()

      this.clipboard = new Clipboard(this.$refs.copyButton);
  },

  destroyed() {
    $(".modal").modal("hide");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
    this.$notify.close();
  },
};
</script>



<style lang="scss" scoped>
.search-card {
    margin-bottom: 0;

    .card-body {
        padding: 1.25rem;
        padding-bottom: 0;
    }
}

.yahome-table-wrap {
    padding: 0 20px !important;
    border-radius: 0px;
}

.block {
    padding: 20px;
    padding-bottom: 0;
    background-color: #fff;
}

.row .form-group label {
    font-size: 0.8em;
}

#qrcodeModal {
    .modal-content {
        background-color: rgba(0, 0, 0, 0.6);
    }

    .modal-body {
        text-align: center;

        .qrcode-wrap {
            display: inline-block;
            background-color: #fff;
            padding: 10px;
            border-radius: 5px;
        }
    }
}
</style>
